.video {
  position: absolute;
  right: -50;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  background-size: cover;
  overflow: hidden;
}

.eagle-video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.title {
  font-weight: bold;
  font-size: 150px;
  text-align: left;
  letter-spacing: 1px;
  font-family: 'Geneva';
  color: white;
  padding: 200px 500px 0 100px;
}

.text {
  position: relative;
  font-size: 20px;
  text-align: left;
  letter-spacing: 1px;
  font-family: 'Geneva';
  color: white;
  padding: 0 500px 0 100px;
}

.text-description {
  position: absolute;
  top: 190px;
  bottom: 40px;
  left: 40px;
}

.text-headline {
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 40px;
}

.logo-first-tee {
  position: absolute;
  bottom: 92px;
  right: 40px;
}

.logo-morgan-stanley {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
