/* Home.css */
.home-link {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.text-link {
  color: white;
  font-weight: bold;
  font-size: 25px;
}
